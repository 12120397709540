<form #form="ngForm">
    <ng-container *ngIf="company">
        <div class="app-grid centered">
            <mat-form-field class="columns-7">
                <mat-label appRemoveAriaOwns>{{
                    'company.payroll-info-cra-form.remitterTypeLabel' | translate
                }}</mat-label>
                <mat-select
                    #remitterType="ngModel"
                    [(ngModel)]="company.remitterType"
                    name="remitterType"
                    [required]="required"
                >
                    <mat-option
                        *ngFor="let remitterTypeListItem of remitterTypesList"
                        [value]="remitterTypeListItem.key"
                        >{{ remitterTypeListItem.value | translate }}</mat-option
                    >
                </mat-select>
                <mat-error *ngIf="remitterType.invalid">{{ remitterType.errors | formErrors }}</mat-error>
            </mat-form-field>
            <ui-info class="columns-1" text="company.payroll-info-cra-form.contactCra"></ui-info>
            <div class="columns-4">
                <a target="_blank" href="{{ '12156278935063' | i18nHelpCentre }}">
                    {{ 'company.payroll-info-cra-form.learnAboutRemitterTypes' | translate }}
                </a>
            </div>
            <mat-form-field class="columns-4 small-columns-11" [class.read-only-payroll-info-field]="onboarded">
                <mat-label>{{ 'company.payroll-info-cra-form.craBusinessNumberLabel' | translate }}</mat-label>
                <input
                    KYB-Revalidate
                    matInput
                    type="text"
                    #businessNumber="ngModel"
                    name="businessNumber"
                    [(ngModel)]="company.craBusinessNumber"
                    [required]="!onboarded"
                    [readonly]="onboarded"
                    minlength="9"
                    maxlength="9"
                    placeholder="{{ 'company.payroll-info-cra-form.craBusinessNumberPlaceholder' | translate }}"
                />
                <mat-error *ngIf="businessNumber.invalid">{{ businessNumber.errors | formErrors }}</mat-error>
            </mat-form-field>

            <mat-form-field class="columns-4 small-columns-11" *ngIf="!onboarded; else displayReadOnlyProgramCode">
                <mat-label appRemoveAriaOwns>{{
                    'company.payroll-info-cra-form.craProgramCodeLabel' | translate
                }}</mat-label>
                <mat-select
                    KYB-Revalidate
                    name="programCode"
                    [(ngModel)]="company.craProgramCode"
                    [required]="!onboarded"
                    #programCode="ngModel"
                >
                    <mat-option value="RP">RP</mat-option>
                </mat-select>
                <mat-error *ngIf="programCode.invalid">{{ programCode.errors | formErrors }}</mat-error>
            </mat-form-field>
            <ng-template #displayReadOnlyProgramCode>
                <mat-form-field class="columns-4 small-columns-11 read-only-payroll-info-field">
                    <mat-label>{{ 'company.payroll-info-cra-form.craProgramCodeLabel' | translate }}</mat-label>
                    <input
                        KYB-Revalidate
                        matInput
                        type="text"
                        #programCode="ngModel"
                        name="programCode"
                        [(ngModel)]="company.craProgramCode"
                        [readonly]="onboarded"
                    />
                </mat-form-field>
            </ng-template>

            <mat-form-field class="columns-4 small-columns-11" [class.read-only-payroll-info-field]="onboarded">
                <mat-label>{{ 'company.payroll-info-cra-form.craReferenceNumberLabel' | translate }}</mat-label>
                <input
                    matInput
                    type="text"
                    #referenceNumber="ngModel"
                    name="referenceNumber"
                    [(ngModel)]="company.craReferenceNumber"
                    [required]="!onboarded"
                    [readonly]="onboarded"
                    minlength="4"
                    maxlength="4"
                    placeholder="{{ 'company.payroll-info-cra-form.craReferenceNumberPlaceholder' | translate }}"
                />
                <mat-error *ngIf="referenceNumber.invalid">{{ referenceNumber.errors | formErrors }}</mat-error>
            </mat-form-field>
        </div>
    </ng-container>
</form>
