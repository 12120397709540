<form autocomplete="nope" #form="ngForm" class="app-grid">
    <ng-container *ngIf="assignee && assetReassignment">
        <!-- Assign To -->
        <mat-form-field class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.assignedTo' | translate }}</mat-label>
            <ui-employee-autocomplete
                name="assignedTo"
                [(ngModel)]="assignee"
                [placeholder]="'forms.assets.unassigned' | translate"
                #assignedTo="ngModel"
                [disabled]="true"
            ></ui-employee-autocomplete>
        </mat-form-field>

        <!-- Date Returned -->
        <mat-form-field class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.dateReturned' | translate }}</mat-label>
            <input
                matInput
                name="dateReturned"
                [min]="assetAssignment.assignedOn"
                [matDatepicker]="dateReturnedPicker"
                #dateReturned="ngModel"
                required
                [(ngModel)]="assetReassignment.returnedOn"
            />
            <mat-datepicker-toggle matSuffix [for]="dateReturnedPicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dateReturnedPicker></mat-datepicker>
        </mat-form-field>

        <!-- Re-Assign To -->
        <mat-form-field class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.reassignTo' | translate }}</mat-label>
            <ui-employee-autocomplete
                name="reassignedTo"
                [(ngModel)]="assetReassignment.employee"
                [allowNone]="true"
                noneLabel="forms.assets.unassigned"
                [placeholder]="'forms.assets.unassigned' | translate"
                #reassignedTo="ngModel"
            ></ui-employee-autocomplete>
        </mat-form-field>

        <!-- Date Assigned -->
        <mat-form-field *ngIf="assetReassignment.employee" class="columns-8 small-columns-12">
            <mat-label>{{ 'forms.assets.dateAssigned' | translate }}</mat-label>
            <input
                matInput
                name="dateReassigned"
                [min]="assetReassignment.returnedOn"
                [matDatepicker]="dateReassignedPicker"
                #dateRessigned="ngModel"
                required
                [(ngModel)]="assetReassignment.assignedOn"
            />
            <mat-datepicker-toggle matSuffix [for]="dateReassignedPicker">
                <mat-icon matDatepickerToggleIcon svgIcon="calendar"></mat-icon>
            </mat-datepicker-toggle>
            <mat-datepicker #dateReassignedPicker></mat-datepicker>
        </mat-form-field>
    </ng-container>
</form>
