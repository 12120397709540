import { Directive, effect, Inject, InjectionToken, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';
import { TranslatableKey } from '@app/types/translatable.type';
import { TranslateService } from '@ngx-translate/core';
import { isNil } from 'lodash-es';
import { isMoment } from 'moment';
import { Observable, Subscription } from 'rxjs';
import { filter, map, startWith, takeWhile, tap } from 'rxjs/operators';

export const FIELD_REVALIDATION_MESSAGE = new InjectionToken<TranslatableKey>('FieldRevalidationWarningMessage');
export const FIELD_REVALIDATION_DISPLAY_CONDITION = new InjectionToken<Observable<boolean>>(
    'FieldRevalidationDisplayCondition'
);

@Directive()
export class BaseRevalidationWarningDirective {
    private displayConditionSubscription?: Subscription;
    private initialFieldValue: string = '';
    private initialHintLabel = '';
    private shouldHideWarning?: boolean;
    private fieldStatus = signal<'DIRTY' | 'CLEAN'>('CLEAN');
    private language = toSignal(
        this.translateService.onLangChange.pipe(
            map((e) => e.lang),
            startWith(this.translateService.currentLang)
        )
    );

    private get formFieldNativeElement(): HTMLElement {
        return this.matFormField._elementRef.nativeElement;
    }

    /**
     * Subscribes to field state changes and adds a warning to the field if necessary
     */
    private toggleWarning = effect(() => {
        this.language(); // We don't consume the language value, but we use translate.instant to display the message, therefore we should re-run the message when the user's language changes

        if (this.fieldStatus() === 'CLEAN') {
            this.removeWarning();
            return;
        }
        this.addWarning();
    });

    constructor(
        private control: NgControl,
        private matFormField: MatFormField,
        private translateService: TranslateService,
        @Inject(FIELD_REVALIDATION_MESSAGE) protected message: TranslatableKey, // The message to be displayed when the field is changed
        @Inject(FIELD_REVALIDATION_DISPLAY_CONDITION) protected displayCondition: Observable<boolean>
    ) {
        this.subscribeToDisplayConditionChanges();
        this.subscribeToFieldChanges();
    }

    addWarning(): void {
        this.matFormField.hintLabel = this.translateService.instant(this.message);
        this.formFieldNativeElement.classList.add('humi-warning');
    }

    removeWarning(): void {
        this.matFormField.hintLabel = this.initialHintLabel;
        this.formFieldNativeElement.classList.remove('humi-warning');
    }

    /**
     * There are sometimes complex logic behind whether we should display the warning or not.
     * This function will asynchronously unsubscribe/re-subscribe to the form control value changes based on the display condition
     */
    protected subscribeToDisplayConditionChanges(): void {
        this.displayCondition.pipe(takeUntilDestroyed()).subscribe((isDisplayConditionMet) => {
            this.shouldHideWarning = !isDisplayConditionMet;
            // In case the display condition is met asynchronously AFTER it's already been unsubscribed due to it initially not being met
            if (isDisplayConditionMet && !this.displayConditionSubscription) {
                this.subscribeToFieldChanges();
            }
        });
    }

    protected subscribeToFieldChanges(): void {
        // Don't subscribe again if we already have a subscription
        if (this.displayConditionSubscription || !this.control.valueChanges) {
            return;
        }
        this.displayConditionSubscription = this.control.valueChanges
            .pipe(
                takeUntilDestroyed(),
                map(() => {
                    // Some fields annoyingly use '', null, and undefined interchangeably which is hard for equality comparisons so we coerce them to the same value here
                    if (isNil(this.control.value)) {
                        return '';
                    }
                    // Similarly moment equality comparisons are difficult (namely in the ui-datepicker)
                    if (isMoment(this.control.value)) {
                        return this.control.value.format('YYYY-MM-DD HH:mm:ss');
                    }
                    return this.control.value;
                }),
                tap((controlValue: string) => {
                    if (!this.control.dirty) {
                        // We keep track of the initial value so we can compare to see if the user has made changes
                        this.initialFieldValue = controlValue;
                        // We keep track of the initial hint label in case we overwrite it using this directive
                        this.initialHintLabel = this.matFormField.hintLabel;
                    }
                }),
                // Unsubscribes if shouldHideWarning becomes truthy (done asynchronously in subscribeToDisplayConditionChanges)
                takeWhile(() => !this.shouldHideWarning),
                // Filters values set before shouldHideWarning is set (it initializes as undefined)
                // This ensures we don't display the warning prior to the displayCondition being set asynchronously
                filter(() => this.shouldHideWarning === false),
                filter(() => !!this.control.dirty)
            )
            .subscribe((controlValue) => {
                if (controlValue !== this.initialFieldValue) {
                    this.fieldStatus.set('DIRTY');
                    return;
                }
                this.fieldStatus.set('CLEAN');
            });
    }

    /**
     * Allows for disabling of the directive conditionally if necessary
     */
    protected unsubscribeFromFieldChanges(): void {
        if (!this.displayConditionSubscription) {
            return;
        }
        this.displayConditionSubscription.unsubscribe();
        this.displayConditionSubscription = undefined;
    }
}
